import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  color?:
    | 'transparent'
    | 'primary'
    | 'seller'
    | 'grey'
    | 'green'
    | 'red'
    | 'seller-outlined';
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  width?: 'full' | 'fit';
}

const CatalogButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      children,
      color,
      type = 'button',
      size = 'medium',
      width = 'full',
      ...props
    },
    ref,
  ): JSX.Element => {
    const innerRef = useRef<HTMLButtonElement>(null);
    const bgClass = useMemo(() => {
      switch (color) {
        case 'seller':
          return 'bg-seller';
        case 'primary':
          return 'bg-primary-50';
        case 'green':
          return 'bg-success-50';
        case 'grey':
          return 'bg-grey2-10';
        case 'red':
          return 'bg-error-50';
        case 'transparent':
        default:
          return 'bg-white';
      }
    }, [color]);

    const widthClass = useMemo(() => {
      switch (width) {
        case 'fit':
          return 'w-fit';
        case 'full':
        default:
          return 'w-full';
      }
    }, [width]);

    useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement);

    return (
      <button
        data-testid="catalog-button"
        ref={innerRef}
        {...props}
        type={type}
        className={clsx(
          bgClass,
          widthClass,
          'inline-flex w-fit items-center justify-center gap-3 rounded-full border  border-grey2-30 px-4 py-2 text-sm font-normal text-grey2-60  enabled:hover:bg-grey-30 focus:outline-none focus:ring-offset-2',
          {
            'text-white enabled:hover:bg-primary-40 disabled:bg-primary-20':
              color === 'primary' || color === 'red',
          },
          {
            'text-sellerFont enabled:hover:bg-seller/80 disabled:bg-seller/20 disabled:border-0':
              color === 'seller',
          },
          {
            'text-white enabled:hover:bg-success-40 disabled:bg-success-10':
              color === 'green',
          },
          {
            'border-0 text-grey2-60 enabled:hover:bg-grey2-30 disabled:bg-grey2-10':
              color === 'grey',
          },
          {
            'border border-seller text-seller hover:text-sellerFont enabled:hover:bg-seller disabled:text-grey2-40 disabled:border-grey2-10 disabled:bg-white':
              color === 'seller-outlined',
          },
          { 'h-12': size === 'large' },
          { 'h-10': size === 'medium' },
          { 'h-8': size === 'small' },
        )}
      >
        {children}
      </button>
    );
  },
);

CatalogButton.displayName = 'CatalogButton';

export default CatalogButton;
